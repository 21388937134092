<template>
  <div class="page page__content-wrapper smm">
    <div class="page__full-width banner-block">
      <div class="page__min-container ">
        <div class="page__text-block">
          <div class="text__head">
            <div class="top-border"></div>
            Баннер
          </div>
        </div>
      </div>
      <div class="page__link-block download-block">
        <a :href="item.url"
           @click.prevent="downloadItem(item)"
           class="btn__circle btn__nav-slider  btn-download">
          <img src="~assets/img/icons/icon-mp4.svg" alt="mp-4">
        </a>
      </div>
    </div>


    <div class="page__full-width smm__block-info-row m-zero">
      <div class="page__min-container page__img-wrapper m-zero">
        <img src="~assets/img/smm-page/smm-1.png" alt="Баннер">
      </div>
    </div>

<!--    <div class="page__min-container slider-smm-mobile">-->
<!--      <div class="text__bold smm__sub-title">-->
<!--        Обложка-->
<!--      </div>-->
<!--      <div class="smm__slider-wrap">-->
<!--        <StylePageSlider v-bind:slides="imgSlides"/>-->
<!--      </div>-->
<!--    </div>-->

    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/brand/transport">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Транспорт
      </router-link>
      <router-link class="next-page__link" to="/">
        В начало
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/">
        В начало
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>
// import StylePageSlider from "@/components/Sliders/StylePageSlider";

import Axios from "axios";

export default {
  components: {
    // StylePageSlider
  },
  data(){
    return{
      item: {
        url: '../dowload-file/Brand--Office/smm/GDP-smm-background.zip',
        label: "GDP-smm-background"
      }
    }
  },
  methods: {
    downloadItem({url, label}) {
      Axios.get(url, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";
.page {
  margin-bottom: 204px;
  .page__full-width {
    .page__min-container {
      margin-bottom: 0;
      .page__text-block {
        .text__head {
          margin-bottom: 0;
          font-weight: 500;
          .top-border {

          }
        }
      }
    }
    .page__link-block {
      margin-left: 20px;
    }
  }
}
.page__text-block {
  max-width: 100%;
  width: 100%;
}
.smm {
  &__block-info{
    &-row{
      display: flex;
      align-items: flex-start;
      margin-top: -16px;
      margin-bottom: 128px;
    }
  }
  &__sub-title {
    min-width: 112px;
  }
  &__avatar{
    width: 144px;
    height: 144px;
    border-radius: 50%;
    background: linear-gradient(264.59deg, #FF3D3D 0%, #F96400 100%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__btn-gradient{
    border: 0.5px solid #F96400;
    border-radius: 24px;
    padding: 7px 16px;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #F96400;
    margin-left: 84px;
    cursor: pointer;
  }
}
.banner-block{
  display: flex;
}
.m-zero{
  margin-bottom: -16px;
}
@media screen and (max-width: 1000px){
  .smm__block-info-row{
    padding-top: 20px;
    flex-wrap: wrap;
  }
  .smm__btn-gradient{
    margin-top: 20px;
  }
}
@media screen and (max-width: 900px){
  .page__full-width {
    width: 100%;
  }
  .slider-smm-mobile{
    flex-direction: column;
    display: flex;
    align-items: flex-start;
  }
  .text__bold.smm__sub-title{
    margin-bottom: 32px;
  }
  .smm__slider-wrap{
    width: 100%;
  }
  .smm__slider-wrap .style-page__nav-block{
    flex-wrap: wrap;
    .btn__nav-slider{
      min-width: 56px;
      min-height: 56px;
    }
  }
  .style-page__nav-block{
    flex-wrap: wrap;
  }
  .smm__block-info-row{
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .smm__btn-gradient{
    position: absolute;
    margin: 0;
    top: 125px;
    left: 175px;
  }
  .smm__avatar{
    position: relative;
    z-index: 1;
  }
  .smm__btn-gradient:before{
    content: '';
    display: flex;
    width: 100%;
    height: 1px;
    background: #F96400;
    position: absolute;
    top: 16px;
    left: -100%;
    z-index: 0;
  }
  .smm__block-info-row{
    margin-bottom: 64px;
    margin-right: 0;
    padding-left: 5px;
    width: 100%;
  }
}
</style>
